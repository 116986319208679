@import './global.scss';

.footer{
    width: 100%;
    background-color: $color-title;
    //color: $color-background;
    .inner-footer{
        width: 100%;
        max-width: 1200px;
        padding: 60px 60px 20px 60px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
}

.footer-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .left-footer{
        display: flex;
        .logo-footer{
            color: $color-background;
            img{
                width: 100px;
            }
        }
       .links-footer{
        padding-left: 40px;
        .link{
            p{
                color: $color-background;
                padding-bottom: 8px;
            }
        }
       }
    }
    .right-footer{
        display: flex;
        .link-r{
            padding-left: 8px;
        }
    }
}

.rights{
    text-align: center;
    border-top: 1px solid #fff;
    p{
        padding-top: 20px;
        color: $color-background;
        font-size: 14px;
    }
}

@media (min-width: 800px) and (max-width: 1050px) {
     .inner-footer{
         padding: 60px 40px 20px 40px;
     }
   }
   
@media (min-width: 299px) and (max-width: 799px) {
    .footer .inner-footer{
        padding: 60px 20px 20px 20px;
    }
    .footer-container{
        flex-direction: column;
        
    }
    .left-footer{
        justify-content: space-between;
    }
    .right-footer{
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-top: 90px;
    }
    .rights{
        p{
            font-size: 12px;
        }
    }
         
}