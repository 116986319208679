.clients{
    position: relative;
    width: 100%;
    height: auto;
    .inner-clients{
        width: 100%;
        max-width: 1400px;
        padding: 60px 60px 80px 60px;
        margin: 0 auto;
        h3{
            text-align: center;
            margin-bottom: 60px;
            font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 150%;
        }
    }
}

.logos-grid{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 1%;
    .logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 70px;
        img{
            width: 100%;
            max-width: 140px;
            height: 100%;
            object-fit: cover;
        }
    }
}

#clientes {
    position: absolute;
    top: -90px;
  }

@media (min-width: 800px) and (max-width: 1050px) {
    .clients .inner-clients{
     padding: 60px 40px;
     h3{
        font-size: 21px;
     }
    }
    .logos-grid{
        grid-template-columns: repeat(4, 1fr);
        .logo{
            margin-bottom: 40px;
        }
    }
   }
   
   @media (min-width: 299px) and (max-width: 799px) {
    .clients .inner-clients{
        padding: 60px 20px;
        h3{
            font-size: 18px;
            padding: 0 20px;
         }
       }
       .logos-grid{
        grid-template-columns: repeat(2, 1fr);
        .logo{
            margin-bottom: 40px;
        }
    }
   }