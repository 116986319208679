@import './global.scss';

.about{
    width: 100%;
    background-color: #FAFAFA;
    padding: 60px;
    position: relative;
}

.inner-about{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.about-text{
    width: 60%;
    margin: 0 auto;
    h2{
        padding-bottom: 32px;
    }
    p{
        font-family: Plus Jakarta Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        max-width: 80%;
        span{
            color: $color-primary;
            font-weight: 700;
        }
    }
}
.about-img{
    width: 40%;
    max-width: 385px;
    height: 415px;
    margin: 0 auto;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }
}

#nosotros {
    position: absolute;
    top: -90px;
  }

@media (min-width: 800px) and (max-width: 1050px) {
   .about{
    padding: 40px;
   }
  }
  
  @media (min-width: 299px) and (max-width: 799px) {
    .about{
        padding: 60px 20px;
        .inner-about{
            flex-direction: column-reverse;
        }
       }
       .about-text{
        width: 100%;
        p{
            max-width: none;
            font-size: 16px;
        };
       }
       .about-img{
        width: 100%;
        margin-bottom: 45px;
       }
  }
  

