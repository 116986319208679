.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e3e3e3;
  min-height: 300px;
  min-width: 300px;
  margin-bottom: 20px;


  &:hover {
    transform: scale(1.02); /* Reduzco la escala para hacerlo más sutil */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra más sutil */
  }

  .card-img {
    width: 100%;
    height: 240px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-title {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 8px !important;
    padding: 12px 8px;

    p {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 110% !important;
      margin: 0;
    }
  }
}


  .grid-cards{
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 160px 60px 40px 60px;
  }

  .section-page{
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    h3{
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p{
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding-top: 9px;
    }
  }

  .left-align{
    text-align: left;
  }

  @media (min-width: 800px) and (max-width: 1050px) {
    .grid-cards{
      padding: 160px 40px 40px 40px;
    }
   }

  @media (min-width: 299px) and (max-width: 799px) {
    .grid-cards{
      padding: 180px 20px 40px 20px;
    }
    .card-title p{
      font-size: 16px !important;
    }
    .section-page{
      h3{
        font-size: 25px;
      }
      p{
        font-size: 14px;
      }
    }
  }
  
  