.product-favorites{
    width: 100%;
    .product-favorites-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1600px;
        position: relative;
        padding: 60px 20px;
    }

    .title-section{
padding-bottom: 10px;
    }

    .link-products{
        width: 100%;
        text-align: center;
        margin-top: 40px;
    }

    .enpa-grid {
        display: flex;
        overflow-x: auto; // Habilita el scroll horizontal
        padding-bottom: 20px; // Añade espacio para el scrollbar
        margin-bottom: -20px; // Compensa el espacio negativo al agregar padding
        white-space: nowrap; // Evita que las tarjetas se envuelvan a la siguiente línea

        .card-container {
            margin-right: 20px; // Espacio entre las tarjetas
        }
    }


      @media (min-width: 299px) and (max-width: 799px) {
        .product-favorites-container{
            padding: 60px 20px;
        }
      }
}