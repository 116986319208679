.slide {
  position: relative;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.slide-content {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 20px;
  z-index: 2; 
  p {
    font-size: 21px;
    line-height: 30px;
    padding: 10px 0;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1050px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;

    h1 {
      font-size: 3.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

.call-to-action {    
      width: 65%;  
      height: 100%; 
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 100%;

    .salto{
      display: none;
    }
      }
}
.btn-container {
    margin-top: 60px;
}

.slick-prev,
.slick-next {
font-size: 18px;
line-height: 1;
position: absolute;
border-radius: 50%;
top: 50%;
z-index: 1;
display: block;
cursor: pointer;
transition: background-color 0.3s ease;
&:hover {
  background: none; /* Cambia el color de fondo al pasar el ratón */
}
}

.slick-prev:hover,
.slick-next:hover {
background-color: rgba(255, 255, 255, 0.8);
}

.slick-prev {
left: 10px;
}

.slick-next {
right: 10px;
}

  