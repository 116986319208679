@import './global.scss';

.header{
    width: 100%;
    position: fixed;
    transition: all 0.3s ease;
    background-color: $color-background;
    z-index: 999;
    height: 112px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .topBar-box{
      height: auto;
    }
    .inner-header{
      width: 100%;
      height: 75px;
      top: 15px;
      max-width: 1600px;
      padding: 0 20px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .brand img{
    width: 90px;
  }
  
  .hamburguer{
    display: none;
  }


  .navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    .link {
      padding: 0 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      cursor: pointer;
      transition: color 0.3s ease, transform 0.3s ease;
      text-transform: uppercase;
      letter-spacing: 0.3px;
  
      &:hover {
        color: $color-primary;
        transform: translateY(-2px);
      }
    }
  }
  
  

  

    .topBar{
      position: relative;
      top: 0;
      left: 0;
      text-align: center;
      padding: 8px 0;
      background-color: $color-title;
      color: $color-background;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      span{
        color: $color-background;
        background-color: $color-title;
        font-weight: bold;
      }
    }
  
  
  @media (min-width: 299px) and (max-width: 799px) {
    .header .inner-header{
      padding: 0 20px;
    }
  
    .hamburguer{
      display: block;
    }
  
    .navbar{
      width: 100%;
      flex-direction: column;
      position: absolute;
      top: 112px;
      left: 0;
      height: 100vh;
      padding: 40px 0;
      overflow: hidden;
      transform: translateX(100%);
      transition: all 0.5s ease;
      max-height: 0;
      background-color: #fff;
      border-top: 1px solid #f3f3f3;
      .link{
        padding: 15px 0;
        width: 100%;
        text-align: center;
        font-size: 18px;
      }
      .btn{
        width: 50%;
        padding: 12px 20px;
      }
    }
  
    .show-navbar{
      max-height: 1000px;
      transform: translateX(0);
    
    }
  }
  
  