.whatsapp {
    position: fixed;
    z-index: 999;
    top: 89%;
    right: 3%;
    padding: 10px 12px;
    background-color: #fff;
    border-radius: 50px;
    font-weight: 500;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
    a{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
    }
    img{
        width: 25px;
        height: auto;
    }
  }


  @media (min-width: 299px) and (max-width: 799px) {
    .whatsapp{
        right: 6%;
        p{
            display: none;
        }
        img{
            width: 35px !important;
        }
    }
  }
  
  