@import './global.scss';

.detail-page{
    width: 100%;
    max-width: 1400px;
    height: auto;
    padding: 160px 60px 90px 60px;
    margin: 0 auto;
    position: relative;
}

.product-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.image{
    width: 60%;
    max-width: 650px;
    height: 430px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.info{
   width: 40%;
   padding-left: 40px;
   h2{
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
   }
   h3{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #818181;
    padding-top: 2px;
   }
   .detail-btn-container{
    width: 100%;
    .detail-button{
        display: block;
        width: 100%;
        text-align: center;
    }
   }
}

.description{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding-top: 22px;
    margin-bottom: 62px;
   }

.product-contact{
    width: 60%;
    margin-top: 30px;
    .measures{
        h2{
            font-size: 24px;
            padding-bottom: 16px;
        }
        .measure-box {
            width: 100%;
        .m-data{
            display: flex;
            justify-content: space-between;
            padding: 12px 20px;
            border-top: 1px solid #0721254d;
        }
        .m-last-data{
            border-bottom: 1px solid #0721254d;
        }
        .product-data{
            display: flex;
            p {
                padding: 0 2px;
            }
        }
    }
    }
}

.product-form{
    position: relative;
    margin-top: 50px;
    h2{
        font-size: 24px;
        padding-bottom: 16px;
    }
}

.banner{
    width: 100%;
    background-color: #FAFAFA;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span{
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 28px;
    }
    h2{
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-align: center;
        max-width: 850px;
        margin-bottom: 30px;
    }
    .banner-link{
        display: flex;
        .b-button{
            background-color: $color-title;
        }
    }
}

.link-ficha-tecnica{
    background: none;
    text-decoration: underline;
    font-weight: bold;
}

#p-form {
    position: absolute;
    top: -140px;
    @media (max-width: 768px) {
      top: 200px;
      }
  }

@media (min-width: 800px) and (max-width: 1050px) {
 .detail-page{
    padding: 160px 40px;
 }  
 .banner{
    padding: 60px 40px;
 }
}
   
@media (min-width: 299px) and (max-width: 799px) {
    .detail-page{
        padding: 160px 20px 40px 20px;
     } 
     
     .product-info{
        flex-direction: column;
        .image{
            width: 100%;
        }
        .info{
            margin-top: 35px;
            width: 100%;
            padding-left: 0;
            h2{
                font-size: 30px;
            }
            h3{
                font-size: 14px;
            }
            p{
                font-size: 16px;
                margin-bottom: 30px;
            }
        }
     }
     .product-contact{
        width: 100%;
        margin-top: 60px;
        .measures{
            h2{
                font-size: 24px;
                padding-bottom: 16px;
            }
            .measure-box {
                width: 100%;
            .m-data{
                padding: 12px 0px;
            }
            .m-last-data{
                border-bottom: 1px solid #0721254d;
            }
            .product-data{
                display: flex;
                p {
                    padding: 0 2px;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
        }
     }
     .banner{
        padding: 60px 20px;
        h2{
            font-size: 30px;
        }
        span{
            font-size: 16px;
        }
     }
}