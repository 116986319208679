$color-primary: #E60000;
$color-background: #ffffff;
$color-title: #050505;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: $color-title;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

body {
  overflow-x: hidden;

}

.general-button {
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 46pX;
  background-color: $color-primary;
  color: $color-background;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  transition: background-color 0.5s ease, transform 0.5s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.general-button:hover {
  background-color: lighten($color-primary, 10%);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.general-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.download-btn {
  background-color: transparent;
  border: 1px solid #050505;
  color: #050505;
  margin-top: 1rem;
  box-shadow: none;
}

.download-btn:hover {
background-color: transparent;
}

h1 {
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  padding-bottom: 6px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

h2 {
  font-size: 40px;
}

p {
  font-size: 16px;
}


button {
  cursor: pointer;
  border: none;
}

.flex-container {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.enpa-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 24%);
  margin-top: 30px;
  column-gap: 1%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
}

.go-back {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    padding: 0 0 0 5px;
  }

  ;
}

.loader {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
}

.none {
  display: flex
}

@media (min-width: 800px) and (max-width: 1050px) {
  .grid-responsive {
    grid-template-columns: repeat(3, 32%);

  }
}

@media (min-width: 299px) and (max-width: 799px) {
  h2 {
    font-size: 32px;
  }

  .grid-responsive {
    grid-template-columns: 100%;
  }
}