.contact{
    position: relative;
    width: 100%;
    height: auto;
    background-color: #FAFAFA;
    .inner-contact{
        width: 100%;
        max-width: 1400px;
        padding: 60px;
        display: flex;
        flex-direction: row;
    }
}

.form-container{
    width: 50%;
    h2{
        margin-bottom: 32px;
    }
    form{
        width: 100%;
        .input{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 12px;
            input{
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: 1px solid var(--input-border, #8787874d);
                background: var(--white, #FFF);
                padding-left: 8px;
                &:focus{
                    border-color: #000000;
                    color: #2a2828;
                }
            }
            label{
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                padding-bottom: 2px;
            }
            textarea{
                height: 60px;
                border-radius: 8px;
                border: 1px solid var(--input-border, rgba(135, 135, 135, 0.3));
                background: var(--white, #FFF);
                padding-left: 8px;
                padding-top: 8px;
            }
        
        }
    }
}

.form-contact{
    width: 100%;
}

.contact-data{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .data-contaimer{
        display: flex;
        flex-direction: column;
    }
    .data{
        margin-bottom: 30px;
        h3{
            padding-bottom: 10px;
        }
        p{
            padding-bottom: 5px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; 
            a:hover{
                text-decoration: underline;
            }
        }
    }
}

#contacto {
    position: absolute;
    top: -90px;
  }

@media (min-width: 800px) and (max-width: 1050px) {
    .inner-contact{
     padding: 40px;
    }
   }
   
   @media (min-width: 299px) and (max-width: 799px) {
    .contact{
        .inner-contact{
            flex-direction: column;
            padding: 60px 20px;
        }
    }
    .form-container{
        width: 100%;
        margin-bottom: 40px;
    }

    .contact-data{
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
    }
         
    }
   